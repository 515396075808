import React, { useState, useEffect } from 'react';
import './ImageCarousel.css';

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextSlide();
    }, 7000);

    return () => clearInterval(interval);
  });

  const goToPrevSlide = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
  };

  const handleIndicatorClick = index => {
    setCurrentIndex(index);
  };

  return (
      <div className="carousel">
        <button className="prev" onClick={goToPrevSlide}>
          &#8678;
        </button>
        <button className="next" onClick={goToNextSlide}>
          &#8680;
        </button>
        <img src={images[currentIndex]} alt="carousel" />
        <div className="indicators">
          {images.map((image, index) => (
            <span
              key={index}
              className={currentIndex === index ? 'indicator active' : 'indicator'}
              onClick={() => handleIndicatorClick(index)}
            ></span>
          ))}
        </div>
      </div>
  );
};

export default ImageCarousel;
