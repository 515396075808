import React from 'react';
import { Link } from 'react-router-dom';


export default function NavLinks({ page }) {
  const pages = ["Home", "Who we are", "Our clubs", "Membership", "Events", "Safeguarding", "Contact us"];


  return (
    <nav>
        <ul>
            {pages.map((link, index) => (
                <li key={index}>
                    <Link key={index} to={`/${link.replace(/\s+/g, '-').toLowerCase()}`} className={`link ${link === page ? 'currentPage' : ''}`}>
                        {link}
                    </Link>
                </li>
            ))}
        </ul>
    </nav>
  );
}
