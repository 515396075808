import React from 'react';
import { useTabContext } from '../../Context';
import './Search.css';

function Search() {
  const { searchTerm, setSearchTerm } = useTabContext();

  const handleChange = (event) => {
    const searchedValue = event.target.value;
    setSearchTerm(searchedValue);
  };

  return (
    <div>
      <input
        type="text"
        id="town-search"
        value={searchTerm}
        onChange={handleChange}
        placeholder="Enter town name"
        className="search"
        title="Search for a town to find a club"
      />
    </div>
  );
};

export default Search;
