import React from 'react';
import './QuickLinkCard.css';

function QuickLinkCard(props) {
  const { title, image, bio, link, linkText, alt } = props;
  
  return (
    <div className="quickLinkCard">
      <div className="imageContainer">
        <img src={image} className="quickLinkImage" alt={alt} />
      </div> 
      <div className="quickLinkCardContainer">
        <h2 className="quickLinkTitle">{title}</h2>
        <p>{bio}</p>
        <br />
        { link && linkText ?
          <a href={link} className="quickLinkText">{linkText}</a>
        : null }
      </div>
    </div>
  );
}

export default QuickLinkCard;
