import * as React from 'react';
import Coach from '../Coach/Coach';
import {
    Data,
} from '../../../data.js';

export default function Coaches() {
    const data = Data();
    return (
        <div>
            {data.coaches.map(coach => (
                <Coach
                    name={coach.details.name}
                    role={coach.details.role}
                    image={coach.images.list}
                    type="coach"
                    key={coach.details.name}
                />
            ))}
        </div>
    );
}
