import * as React from 'react';
import WhoWeAreVerticalTabs from './Tabs/Tabs';
import WhoWeAreHorizontalTabs from './TabsMobile/Tabs';
import './WhoWeAre.css';

export default function WhoWeAreContent() {
    return (
        <div>
            <div id="vertical">
                <WhoWeAreVerticalTabs />
            </div>
            <div id="horizontal">
                <WhoWeAreHorizontalTabs />
            </div>
        </div>
    );
}
