import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import TabContextProvider from './Context';
import Home from './pages/Home/Home';
import './App.css';
import WhoWeAre from './pages/WhoWeAre/WhoWeAre';
import OurClubs from './pages/OurClubs/OurClubs';
import Membership from './pages/Membership/Membership';
import Events from './pages/Events/Events';
import Safeguarding from './pages/Safeguarding/Safeguarding';
import ContactUs from './pages/ContactUs/ContactUs';
import Profile from './pages/Profile/Profile';
import ViewClub from './pages/ViewClub/ViewClub';

function App() {
  return (
    <BrowserRouter>
      <TabContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/our-clubs" element={<OurClubs />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/profile/:type/:name" element={<Profile />} />
          <Route path="/club/:club" element={<ViewClub />} />
          <Route path="/safeguarding" element={<Safeguarding />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </TabContextProvider>
    </BrowserRouter>
  );
}

export default App;
