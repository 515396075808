import React, { useState } from 'react';
import Logo from '../../assets/Images/nikb_Logo_BLACK.png';
import Facebook from '../../assets/Images/facebook-app-round-white-icon.webp';
import Instagram from '../../assets/Images/white-instagram-icon-png.png';
import TikTok from '../../assets/Images/TIKTOK-ICON-WHITE.png';
import YouTube from '../../assets/Images/youtube-app-white-icon.webp';
import './Navbar.scss';
import NavLinks from './atoms/NavLinks';
import { useNavigate } from 'react-router-dom';
import { Constants } from '../../constants.js';

export default function Navbar({ page }) {
  const [menuOpen, setMenuOpen] = useState(true);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  function goHome() {
    navigate('/home');
  }

  return (
    <div className="navbar">
      <div className="socials">
        <div id="followText">Follow us:</div>
        <a href={Constants().socials.facebook}><img src={Facebook} alt="Facebook Icon" id="icon" /></a>
        <a href={Constants().socials.instagram}><img src={Instagram} alt="Instagram Icon" id="icon" /></a>
        <a href={Constants().socials.tiktok}><img src={TikTok} alt="TikTok Icon" id="icon" /></a>
        <a href={Constants().socials.youtube}><img src={YouTube} alt="YouTube Icon" id="icon" /></a>
      </div>
      <div className="header">
        <div className="logo">
          <img src={Logo} className="logoImage" alt="NIKB logo" onClick={goHome} />
        </div>
        <div className="subHeader">
          <h1 className="title">Northern Ireland Karate Board</h1>
          <div className="navigation">
            <div className="navigation-menu">
              <div className="mobile-nav-menu">
                <div className={`burger-icon ${!menuOpen ? 'open': ''}`} onClick={toggleMenu}>
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </div>
                <div className={`burger-menu ${!menuOpen ? 'open': ''}`}>
                    <NavLinks page={page} />
                </div>
              </div>
              <div className='nav-menu'><NavLinks page={page} /></div>
            </div>
          </div>
        </div>
      </div>
      <div className="headerShadow"></div>
    </div>
  );
}
