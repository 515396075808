import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import ImageCarousel from '../../components/Home/ImageCarousel/ImageCarousel';
import Footer from '../../components/Footer/Footer';
import QuickLinkCard from '../../components/Home/QuickLinkCard/QuickLinkCard';
import MembershipPromotion from '../../components/MembershipPromotion/MembershipPromotion';
import carousel1 from '../../assets/Images/carousel1.jpeg';
import carousel2 from '../../assets/Images/carousel2.jpeg';
import carousel3 from '../../assets/Images/carousel3.jpeg';
import carousel4 from '../../assets/Images/carousel4.jpeg';
import affiliations from '../../assets/Images/Aff_Logos.png';
import NIKB_header from '../../assets/Images/NIKB_header.jpeg';
import Karate_Safeguarding from '../../assets/Images/karate_safeguarding.png';
import Karate_Events from '../../assets/Images/karate_events.png';
import Learn_Karate from '../../assets/Images/learn_karate.jpeg';
import './Home.css';

function Home() {
    const images = [
        carousel1,
        carousel2,
        carousel3,
        carousel4
    ];

    const [activeMission, setActiveMission] = useState(false);
    const [maxHeightMission, setMaxHeightMission] = useState('0px');

    const handleClickMission = () => {
        setActiveMission(!activeMission);
        setMaxHeightMission(activeMission ? '0px' : '2000px');
    };

    const [activeAff, setActiveAff] = useState(false);
    const [maxHeightAff, setMaxHeightAff] = useState('0px');

    const handleClickAff = () => {
        setActiveAff(!activeAff);
        setMaxHeightAff(activeAff ? '0px' : '1000px');
    };

    return (
        <div className="container">
            <Navbar page="Home" />
            <ImageCarousel images={images} />
            <div className="page">
                <br /><br /><br />
                <img src={NIKB_header} alt="NIKB - The only official karate governing body in NI" id="NIKB_header" />
                <div id="home_about">
                    <h2>About us</h2>
                    <p className="large">The Northern Ireland Karate Board (NIKB) is the unifying body of the sport and is recognised as the only National Governing Body for karate by SportNI in Northern Ireland. NIKB holds membership in the British Karate Federation, the Commonwealth Karate Federation, the European Karate Federation and the World Karate Federation (WKF). The WKF in turn is recognised as the World Governing Body for karate by the International Olympic Committee.</p>
                    <a href="/who-we-are">Learn more...</a>
                </div>
                <iframe className="youtube_video" src="https://www.youtube.com/embed/-ZdkI56Tlbg?si=22b2bZpDCIcVrypH" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen frameBorder="0" sandbox="allow-same-origin allow-scripts allow-presentation"></iframe>
                <div id="mission">
                    <button type="button" className={`collapsible ${activeMission ? 'active' : ''}`} onClick={handleClickMission}><h2>Our mission statement</h2></button>
                    <div
                        className="mission_content large"
                        style={{ maxHeight: `${maxHeightMission}`, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out', backgroundColor: 'var(--pale-green)' }}
                    >
                        <p>We have recently gathered around the drawing board with a completely restructured organisation and a renewed desire to develop a shared vision <strong>“to be the voice that promotes progression, growth, inclusion, diversity. We are committed to furthering the development and excellence of Karate within Northern Ireland.”</strong></p>
                        <p>We have worked diligently to align the goals of our national association with those required by our Sports Council (SportNI) and to continue to strengthen our ties with our fellow National Governing Bodies (NGB) within the 4 Home Nations. This new strategic plan builds on the foundation which we already have in place and will continue to do so as we move forward throughout 2024 and beyond. This plan sets a vision for the NIKB which will see the sport continue to grow throughout Northern Ireland in accordance with the articles and statutes of the British, European and World governing bodies. In addition, this plan lays the foundation for a process to expand our membership, communicate openly and transparently and enhance our value and position for the organisation to maintain a strong and sustainable future.</p>
                        <p><strong>This future looks bright for Karate in Northern Ireland, and we hope it inspires you to join us and make this new vision a future reality.</strong></p>
                    </div>
                </div>
                <div className="affiliations">
                    <img src={affiliations} alt="Affiliations logos" id="BKF_logo"></img>
                    <div id="mission">
                        <button type="button" className={`collapsible ${activeAff ? 'active' : ''}`} onClick={handleClickAff}><h2>The NIKB are affiliated with...</h2></button>
                        <div
                            className="mission_content large"
                            style={{ maxHeight: `${maxHeightAff}`, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out', backgroundColor: 'var(--pale-green)' }}
                        >
                            <div id="aff_list">
                                <li><a href="https://www.wkf.net/">World Karate Federation</a></li>
                                <li><a href="https://2022.europeankaratefederation.net/">European Karate Federation</a></li>
                                <li><a href="https://britishkaratefederation.com/">British Karate Federation</a></li>
                                <li><a href="https://www.commonwealthkarate.net/">Commonwealth Karate Federation</a></li>
                                <li><a href="http://www.sportni.net/">Sport NI</a></li>
                                <li><a href="https://www.ukad.org.uk/">UK Anti-Doping</a></li>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div className="quickLinks">
                    <QuickLinkCard
                        title="Want to learn karate?"
                        image={Learn_Karate}
                        bio="Why don't you check out the clubs we have here in Northern Ireland?"
                        link="/our-clubs"
                        linkText="Find clubs >"
                        alt="NIKB members practicing kata"
                    />
                    <QuickLinkCard
                        title="What events are happening?"
                        image={Karate_Events}
                        bio="Check out our calendar to see NIKB and WKF events happening soon!"
                        link="/events"
                        linkText="See events >"
                        alt="WKF event birdseye view of competetion"
                    />
                    <QuickLinkCard
                        title="What about safeguarding?"
                        image={Karate_Safeguarding}
                        bio="Find out how the NIKB do their part in keeping our athletes safe!"
                        link="/safeguarding"
                        linkText="View safeguarding >"
                        alt="NIKB kids learning karate"
                    />
                </div>
                <MembershipPromotion />
            </div>
            <Footer />
        </div>
    );
}

export default Home;
