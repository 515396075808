import React from 'react';
import { Link } from 'react-router-dom';
import './BackLink.css';

function BackLink() {
  return (
    <div className="back_link">
      <Link to={-1} id="back">&#8678; Go back</Link>
    </div>
  );
}

export default BackLink;
