import React from 'react';
import { useParams } from 'react-router-dom';
import BackLink from '../../components/BackLink/BackLink';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Error from '../../components/Error/Error';
import {
  Data,
} from '../../data.js';
import './ViewClub.css';

function ViewClub() {
  window.scroll(0, 0);

  const { club } = useParams();
  const displayName = club.replaceAll('_', ' ');
  const clubs = Data().clubs;
  let info;
  let error = true;

  for (let i = 0; i < clubs.length; i += 1) {
    if (clubs[i].name === displayName) {
      info = clubs[i];
      error = false;
    }
  }

  return (
    <div className="container">
      <Navbar page="Our clubs" />
      <div className="page">
        <BackLink page={-1} />
        { error ?
          <Error text="Error: This club doesn't exist!" />
        :
          <div className="viewClubInfo">
            <div className="clubInfo">
              <h1>{displayName}</h1>
              <p>{info.about}</p>
              <div className="furtherClubInfo">
                <div className="address">
                  <h2>Address:</h2>
                  <p>{info.address}</p>
                </div>
                <div className="trainingTimes">
                  <h2>Training times:</h2>
                  <p>{info.trainingTimes}</p>
                </div>
              </div>
              <br />
              { info.images.group ?
                <img src={info.images.group} alt={`${displayName} group`} className="clubImages" />
              : null }
              { info.images.location ?
                <img src={info.images.location} alt={`${displayName} location`} className="clubImages" />
              : null }
            </div>
            <div className="contactInfo">
              {info.images.club ?
                <img src={info.images.club} className="clubImages" alt={`${displayName} profile`} />
              : null }
              <br />
              <div className="instructorInfo">
                <h2>Contact information</h2>
                <p><strong>Instructor:</strong> {info.instructor}</p>
                <p><strong>Email:</strong> {info.contactInfo.email}</p>
                <p><strong>Phone:</strong> {info.contactInfo.phone}</p>
              </div>
              <br /><br />
              {info.socialMediaLinks ?
                <div className="socialInfo">
                  <h2>Social media</h2>
                  {info.socialMediaLinks.facebook ?
                    <p><a href={info.socialMediaLinks.facebook}>Facebook</a></p> : null
                  }
                  {info.socialMediaLinks.instagram ?
                    <p><a href={info.socialMediaLinks.instagram}>Instagram</a></p> : null
                  }
                  {info.socialMediaLinks.threads ?
                    <p><a href={info.socialMediaLinks.threads}>Threads</a></p> : null
                  }
                  {info.socialMediaLinks.youtube ?
                    <p><a href={info.socialMediaLinks.youtube}>YouTube</a></p> : null
                  }
                  {info.socialMediaLinks.tiktok ?
                    <p><a href={info.socialMediaLinks.tiktok}>TikTok</a></p> : null
                  }
                </div>
              : null }
            </div>
          </div>
        }
      </div>
      <Footer />
    </div>
  );
}

export default ViewClub;
