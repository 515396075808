import React from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import './Membership.css';

function Membership() {
  return (
    <div className="container">
        <Navbar page="Membership" />
        <div className="page">
          <h1>Affiliate to the NIKB</h1>
          <h2>Affiliate yourself, club or association with the Northern Ireland Karate Governing Body</h2>
          <br />
          <div className="box">
            <h2>The NIKB is the only karate governing body in Northern Ireland recognised by:
              <ul>
                <li>Sport NI</li>
                <li>British Karate Federation</li>
                <li>European Karate Federation</li>
                <li>World Karate Federation</li>
              </ul>
            </h2>
          </div>
          <br />
          <section>
            <h2>Why should you join the NIKB?</h2>
            <h3>Key benefits include:</h3>
            <ul className="large">
              <li>Athletes can represent Northern Ireland through our National Squads</li>
              <li>Access to National, British, European and World Karate Championships</li>
              <li>Licencing and a pathway to Enhanced Access NI checks</li>
              <li>National accreditation courses (for example: coaching, refereeing)</li>
              <li>Access to local grants</li>
              <li>National and international support to progress in Karate</li>
            </ul>
          </section>
          <br />
          <section>
            <h2>NIKB application process</h2>
            <p className="large">Applying for membership to the Northern Ireland Karate Board is easy!</p>
            <p className="large">Email us for further information and/or an application pack at <a href="mailto:secretary@nikb.org">secretary@nikb.org</a> and one of our board members will assist you with each step of the process. Find alternative ways to contact us <a href="/contact-us">here</a>.</p>
          </section>
        </div>
        <Footer />
    </div>
  );
}

export default Membership;
