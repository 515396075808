import React from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import MembershipPromotion from '../../components/MembershipPromotion/MembershipPromotion';
import AntiDoping from '../../assets/Files/Anti Doping Policy.pdf';
import ChildProtection from '../../assets/Files/Child Safeguarding and Adults at Risk Policy.pdf';
import Complaints from '../../assets/Files/Complaints Policy.pdf';
import Disciplinary from '../../assets/Files/Disciplinary Policy.pdf';
import Equality from '../../assets/Files/Equality, Diversity and Inclusion Policy.pdf';
import Accessibility from '../../assets/Files/NIKB Accessibility Statement.pdf';
import GDPR from '../../assets/Files/GDPR.pdf';
import Privacy from '../../assets/Files/Privacy Policy.pdf';
import SocialMedia from '../../assets/Files/Social Media Policy.pdf';
import './Safeguarding.css';

function Safeguarding() {
  return (
    <div className="container">
        <Navbar page="Safeguarding" />
        <div className="page">
          <h1>Safeguarding within the NIKB</h1>
          <h2>Commitment to UKAD and WADA frameworks for clean competitive sports and martial arts and approach to athlete safety and equality</h2>
          <br />
          <section>
            <h2>Anti-doping</h2>
            <p className="large">The NIKB has inplace a set of anti-doping rules that all athletes, coaches and athlete support personnel must abide by. The anti-doping rules for the NIKB are consistent with the World Anti-Doping Code (2015 Code) which governs anti-doping internationally. The anti-doping rules of the NIKB are the UK Anti-Doping Rules published by UK AntiDoping (or any successor bodies), as amended from time to time. Such rules shall take effect and be construed as the rules of Northern Ireland Karate. The NIKB anti-doping rules apply to you, regardless of what level you participate at.</p>
            <p className="large">You can find the UK Anti-Doping Rules <a href="https://www.ukad.org.uk/about/anti-doping-rules">here</a>.</p>
            <p className="large"></p>
            <p className="large">Further information about anti-doping within the NIKB can be found by reading the full <a href={AntiDoping}>Anti-doping Policy</a>.</p>
            <p className="large">We also recommend downloading <a href="https://www.ukad.org.uk/100-me">UKAD's Clean Sport App: 100% me</a> for everything you need to know about clean sport in one place.</p>
            <br />
            <iframe title="UKAD Facebook Post" id="UKAD_post" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FUKAntiDoping%2Fposts%2Fpfbid08EPwhAdLonXf9nemSKLpBT2tDEyv34GTLeyCUAPfUomfbNivXwMTYe6aG7gA42mQl&show_text=true&width=500&is_preview=true" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </section>
          <br /><br />
          <section>
            <h2>Child protection</h2>
            <p className="large">NIKB recognises its responsibility to safeguard and promote the well-being of children, young adults and vulnerable adults. We are dedicated to maintaining the highest standards of safeguarding practices at all times.</p>
            <p className="large">Find out more by reading our <a href={ChildProtection}>Child Protection and Adults at Risk Policy</a> or email <a href="mailto:safeguarding@nikb.org">safeguarding@nikb.org</a>.</p>
          </section>
          <br />
          <section>
            <h2>Unity</h2>
            <p className="large">At the NIKB, we believe in the transformative power of unity within the world of martial arts and sports. We are dedicated to fostering a supportive environment where practitioners and athletes come together to train, compete and grow as a cohesive unit.</p>
            <ul className="large">
              <li><strong>Equality:</strong> Ensure that everyone has fair and equal opportunities</li>
              <li><strong>Diversity:</strong> Be an activity that welcomes individuals from all backgrounds and identities</li>
              <li><strong>Inclusion:</strong> Welcome, value and respect all individuals, regardless of background or identity</li>
            </ul>
            <p className="large">Further information about Unity within the NIKB can be found by reading the full <a href={Equality}>Equality, Diversity and Inclusion Policy</a>.</p>
          </section>
          <br />
          <section>
            <h2>Policies</h2>
            <p className="large">View our policies by clicking the links below.</p>
            <table className="large">
              <tbody>
                <tr>
                  <td>Anti-doping policy</td>
                  <td><a href={AntiDoping}>View</a></td>
                </tr>
                <tr>
                  <td>Child safeguarding and adults at risk policy</td>
                  <td><a href={ChildProtection}>View</a></td>
                </tr>
                <tr>
                  <td>Complaints policy</td>
                  <td><a href={Complaints}>View</a></td>
                </tr>
                <tr>
                  <td>Disciplinary policy</td>
                  <td><a href={Disciplinary}>View</a></td>
                </tr>
                <tr>
                  <td>Equality, diversity and inclusion policy</td>
                  <td><a href={Equality}>View</a></td>
                </tr>
                <tr>
                  <td>GDPR</td>
                  <td><a href={GDPR}>View</a></td>
                </tr>
                <tr>
                  <td>Privacy policy</td>
                  <td><a href={Privacy}>View</a></td>
                </tr>
                <tr>
                  <td>Social media</td>
                  <td><a href={SocialMedia}>View</a></td>
                </tr>
                <tr>
                  <td>Website accessibility statement</td>
                  <td><a href={Accessibility}>View</a></td>
                </tr>
              </tbody>
            </table>
          </section>
          <br /><br />
          <MembershipPromotion />
        </div>
        <Footer />
    </div>
  );
}

export default Safeguarding;
