import React from 'react';
import Home_Membership_Image from '../../assets/Images/home_membership_image.png';
import './MembershipPromotion.css';

function MembershipPromotion() {
    return (
        <div className="homeMembership">
            <div className="trapezium"></div>
            <img src={Home_Membership_Image} alt="NIKB Kumite Athlete in action" />
            <div className="homeMembershipText">
                <h2>Get involved now!</h2>
                <p>Click <a href="/membership">here</a> to affiliate your club with the NIKB today!</p>
            </div>
        </div>
    );
}

export default MembershipPromotion;
