import React, { createContext, useContext, useMemo, useState } from 'react';

const FormContext = createContext();

export function useTabContext() {
    const context = useContext(FormContext);

    const [defaultTab, setDefaultTab] = context.defaultTabValues;
    const [selectedCounty, setSelectedCounty] = context.selectedCountyValue;
    const [searchTerm, setSearchTerm] = context.searchTermValue;

    return {
        defaultTab,
        setDefaultTab,
        selectedCounty,
        setSelectedCounty,
        searchTerm,
        setSearchTerm,
    };
}

function TabContextProvider({ children }) {
    const [defaultTab, setDefaultTab] = useState(0);
    const defaultTabValues = useMemo(() => [defaultTab, setDefaultTab], [defaultTab]);
    const [selectedCounty, setSelectedCounty] = useState('');
    const selectedCountyValue = useMemo(() => [selectedCounty, setSelectedCounty], [selectedCounty]);
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermValue = useMemo(() => [searchTerm, setSearchTerm], [searchTerm]);

    return (
        <FormContext.Provider value={{
            defaultTabValues,
            selectedCountyValue,
            searchTermValue,
        }}>
            {children}
        </FormContext.Provider>
    )
}

export default TabContextProvider;
