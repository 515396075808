import React from 'react';
import './Error.css';

function Error(props) {
  const { text } = props;
  
  return (
    <div className="errorBox">
      <span className="errorText">{text}</span>
    </div>
  );
}

export default Error;
