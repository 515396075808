import React, { useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import AccessibilityStatement from '../../assets/Files/NIKB Accessibility Statement.pdf';
import { Constants } from '../../constants.js';
import './ContactUs.css';

function ContactUs() {
    const [active1, setActive1] = useState(false);
    const [maxHeight1, setMaxHeight1] = useState('0px');

    const handleClick1 = () => {
        setActive1(!active1);
        setMaxHeight1(active1? '0px' : '1000px');
    };

    const [active2, setActive2] = useState(false);
    const [maxHeight2, setMaxHeight2] = useState('0px');

    const handleClick2 = () => {
        setActive2(!active2);
        setMaxHeight2(active2? '0px' : '1000px');
    };

    const [active3, setActive3] = useState(false);
    const [maxHeight3, setMaxHeight3] = useState('0px');

    const handleClick3 = () => {
        setActive3(!active3);
        setMaxHeight3(active3? '0px' : '1000px');
    };

  return (
    <div className="container">
        <Navbar page="Contact us" />
        <div className="page">
            <header>
                <h1>Contact us</h1>
                <p className="large">We're here to assist you with any inquiries, feedback, or support you may need. Below you'll find the various ways to get in touch with us.</p>
            </header>
            <br /><br />
            <section id="contact-info">
                <h2>Contact information</h2>
                <p className="large"><strong>Email:</strong> <a href="mailto:secretary@nikb.org">secretary@nikb.org</a></p>
                <p className="large">Follow us on social media to stay updated on news, events, and more. Feel free to direct message us with any quick questions or comments!</p>
                <ul className="large noBulletPoint">
                    <li><a href={Constants().socials.facebook}>Facebook</a></li>
                    <li><a href={Constants().socials.instagram}>Instagram</a></li>
                    <li><a href={Constants().socials.tiktok}>TikTok</a></li>
                </ul>
            </section>
            <br /><br />
            <section id="faqs">
                <h2>Frequently Asked Questions (FAQs)</h2>
                <div id="faq">
                    <button type="button" className={`collapsible ${active1 ? 'active' : ''}`} onClick={handleClick1}><h3>How can I join a karate club affiliated with the Northern Ireland Karate Board?</h3></button>
                    <div
                        className="faq_content"
                        style={{ maxHeight: `${maxHeight1}`, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out', backgroundColor: 'var(--pale-green)' }}
                    >
                        <p>You can find a list of affiliated clubs on our website's <a className="override_link_style" href="/our-clubs">Clubs page</a>. Contact the club directly for membership details.</p>
                    </div>
                </div>
                <div id="faq">
                    <button type="button" className={`collapsible ${active2 ? 'active' : ''}`} onClick={handleClick2}><h3>How do I register for karate events or competitions?</h3></button>
                    <div
                        className="faq_content"
                        style={{ maxHeight: `${maxHeight2}`, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out', backgroundColor: 'var(--pale-green)' }}
                    >
                        <p>Visit our <a className="override_link_style" href="/events">Events Calendar</a> to see upcoming events and information. Contact your club for registration information.</p>
                    </div>
                </div>
                <div id="faq">
                    <button type="button" className={`collapsible ${active3 ? 'active' : ''}`} onClick={handleClick3}><h3>How can my club become affiliated with the Northern Ireland Karate Board?</h3></button>
                    <div
                        className="faq_content"
                        style={{ maxHeight: `${maxHeight3}`, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out', backgroundColor: 'var(--pale-green)' }}
                    >
                        <p>Check out our <a className="override_link_style" href="/membership">Membership</a> section for all information regarding affiliating your karate club with us.</p>
                    </div>
                </div>
            </section>
            <br /><br />
            <section id="additional-info">
                <h2>Additional information</h2>
                <ul className="large noBulletPoint">
                    <li><strong>NIKB policies:</strong> To read our policies, please visit the <a href="/safeguarding">Safeguarding</a> page.</li>
                    <li><strong>Accessibility statement:</strong> We are committed to making our services accessible to everyone. For details on our accessibility features and accommodations, please see our <a href={AccessibilityStatement}>Accessibility statement</a>.</li>
                </ul>
            </section>
            <br /><br />
            <footer>
                <p className="large">Thank you for reaching out to the Northern Ireland Karate Board. We value your feedback and participation in our karate community. We aim to respond to emails and messages as quickly as possible.</p>
                <p className="large"><strong>For urgent inquiries or issues, please contact us directly via email.</strong></p>
            </footer>
        </div>
        <Footer />
    </div>
  );
}

export default ContactUs;
