import React from 'react';
import '../../WhoWeAre/Coach/Coach.css';
import './BoardMember.css';

function BoardMember(props) {
    const { name, role, image } = props;
    return (
        <div className="container">
            <div className="member">
                <div className="memberDetails">
                    <div id="details">
                        <h1>{name}</h1>
                        <h3 id="role">{role}</h3>
                    </div>
                    <img id="picture_Board" src={image} alt={`${name} headshot`} />
                </div>
            </div>
        </div>
    );
}

export default BoardMember;
