const constants = {
    socials: {
        tiktok: "https://www.tiktok.com/@northernirelandkaratengb",
        instagram: "https://www.instagram.com/northernirelandkarateboard/",
        facebook: "https://www.facebook.com/people/Northern-Ireland-Karate-Board/100008221837569/",
        youtube: "https://www.youtube.com/@NorthernIrelandKarateBoard"
    }
};

function Constants() {
    return constants;
}

export {
    Constants,
};
