import * as React from 'react';

export default function AboutUs() {
    return (
        <section>
            <div>
                <h2>About the Northern Ireland Karate Board</h2>
                <p>The Northern Ireland Karate Board (NIKB) is the unifying body of the sport and is recognised as the only National Governing Body for karate by SportNI in Northern Ireland. NIKB holds membership in the British Karate Federation, the Commonwealth Karate Federation, the European Karate Federation and the World Karate Federation (WKF). The WKF in turn is recognised as the World Governing Body for karate by the International Olympic Committee.</p>
                <p>NIKB currently hosts one annual national championship for NIKB members however discussions are already underway to provide a second, annual open championship. We also have eight separate kumite and kata squad sessions, hosted by our national coaches, at different locations throughout the province which cater for all ages and abilities. These sessions offer continuous tactical improvements in the standard of traditional karate-do and competition karate by developing and sharing best practices to all NIKB members.</p>
            </div>
            <div>
                <h2 className="title_about">Our purpose</h2>
                <p>We aim to provide an environment which enables athletes to achieve sustained competitive excellence and to promote and grow the sport of karate in Northern Ireland whilst maintaining the well-being of our members.</p>
                <p>We aim to foster success across all levels of the karate community whilst ensuring we are inclusive and welcoming of all sections of the community. This will be achieved through the development of strong partnerships with current and new federations and will be structured to fit within our vision and values.</p>
            </div>
            <div>
                <h2 className="title_about">Our values</h2>
                <ul>
                    <li>We will conduct all business ethically and honestly and operate in an open and transparent manner.</li>
                    <li>We will uphold the highest possible standards within our roles and the karate community.</li>
                    <li>We will do our very best to promote the growth, development and excellence of karate.</li>
                    <li>We will operate a democratic organisation and we will value and recognise the contributions of others.</li>
                    <li>We will protect the health and safety of everyone involved in karate and have robust policies in place to safeguard all our members.</li>
                    <li>We will be inclusive in our membership and will address any type of discrimination.</li>
                    <li>We will provide the necessary tools and environment to help facilitate success.</li>
                </ul>
            </div>
        </section>
    );
}
