import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AboutUs from '../TabsContent/AboutUs';
import BoardMembers from '../TabsContent/BoardMembers';
import Coaches from '../TabsContent/Coaches';
import Officials from '../TabsContent/Officials';
import SquadMembers from '../TabsContent/SquadMembers';
import { useTabContext } from '../../../Context';
import './Tabs.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function WhoWeAreHorizontalTabs() {
  const { defaultTab } = useTabContext();
  const [value, setValue] = React.useState(defaultTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ maxWidth: { xs: 320, sm: 600 }, bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Horizontaltabs"
        sx={{ borderRight: 1, borderColor: 'divider' }}
        className="tabs horizontal"
      >
        <Tab label="About us" {...a11yProps(0)} />
        <Tab label="Board members" {...a11yProps(1)} />
        <Tab label="Coaches" {...a11yProps(2)} />
        <Tab label="Officials" {...a11yProps(3)} />
        <Tab label="Athletes" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AboutUs />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BoardMembers />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Coaches />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Officials />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SquadMembers />
      </TabPanel>
    </Box>
  );
}
