import React from 'react';
import Facebook from '../../assets/Images/facebook-app-round-white-icon.webp';
import Instagram from '../../assets/Images/white-instagram-icon-png.png';
import TikTok from '../../assets/Images/TIKTOK-ICON-WHITE.png';
import YouTube from '../../assets/Images/youtube-app-white-icon.webp';
import affiliations from '../../assets/Images/Aff_Logos.png';
import { Constants } from '../../constants.js';
import './Footer.css';

export default function Footer() {
  return (
    <div className="footer">
      <div className="affiliations">
        <img src={affiliations} alt="Affiliations logos" id="BKF_logo"></img>
      </div>
      <div className="main_content">
        <h1 id="title_footer">NIKB</h1>
        <div className="submain_content">
          <div className="links_footer">
            <div className="links_footer1">
              <h2 className="subtitle_footer">Contacts</h2>
              <li>
                <a id="contact_footer" href="/contact-us">Contact us</a>
              </li>
              <li>
                <a id="club_footer" href="/our-clubs">Find a club</a>
              </li>
            </div>
            <div className="links_footer2">
              <h2 className="subtitle_footer">Quick Links</h2>
              <li>
                <a id="policies_footer" href="/membership">Membership</a>
              </li>
              <li>
                <a id="antidoping_footer" href="/events">Events</a>
              </li>
              <li>
                <a id="antidoping_footer" href="/safeguarding">Safeguarding</a>
              </li>
            </div>
          </div>
          <div className="links_footer3">
            <h2 className="subtitle_footer">Socials</h2>
            <a href={Constants().socials.facebook}><img src={Facebook} alt="Facebook Icon" id="icon" /></a>
            <a href={Constants().socials.instagram}><img src={Instagram} alt="Instagram Icon" id="icon" /></a>
            <a href={Constants().socials.tiktok}><img src={TikTok} alt="TikTok Icon" id="icon" /></a>
            <a href={Constants().socials.youtube}><img src={YouTube} alt="YouTube Icon" id="icon" /></a>
          </div>
        </div>
      </div>
      <div id="creator">
        Website creator <a href="https://www.linkedin.com/in/nikkala-pokojski-b19969227">Nikkala Pokojski</a>
        <p><i>This site was created for the NIKB and as such is subject to Copyright ©</i></p>
      </div>
    </div>
  );
}
