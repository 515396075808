import React from 'react';
import { Link } from 'react-router-dom';
import './ClubCard.css';

function ClubCard(props) {
  const { info } = props;
  
  return (
    <div className="container">
      <div className="member">
        <div className="memberDetails">
          <div id="details">
            <h1>{info.name}</h1>
            <h3 id="role">{info.town}</h3>
            <Link to={`/club/${info.name.replaceAll(' ', '_')}`} id="profile_link" className="clubLink">View club &gt;</Link>
          </div>
          <img className="clubLogo" src={info.images.club} alt={`${info.name} logo`} />
        </div>
        <br id="break" />
      </div>
  </div>
  );
}

export default ClubCard;
