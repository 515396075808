import * as React from 'react';
import Coach from '../Coach/Coach';
import {
    Data,
} from '../../../data.js';

export default function SquadMembers() {
    const data = Data();

    return (
        <div>
            <div>
                {data.athletes.map(athlete => (
                    <Coach
                        name={athlete.details.name}
                        role={athlete.details.category}
                        image={athlete.images.list}
                        type="athlete"
                        key={athlete.details.name}
                    />
                ))}
            </div>
        </div>
    );
}
