import Nikkala_Profile from './assets/Images/Nikkala_Profile.jpg';
import Nikkala_Competing from './assets/Images/Nikkala_Competing.jpg';
import Siobhan_Competing from './assets/Images/Siobhan_Competing.png';
import Rachel_Competing from './assets/Images/Rachel_Athlete_Profile.jpeg';
import Bjan_List from './assets/Images/Bjan_List.jpeg';
import Bjan_Action from './assets/Images/Bjan_Action.jpeg';
import Aoibheann_List from './assets/Images/Aoibheann_List.jpeg';
import Aoibheann_Action from './assets/Images/Aoibheann_Action.jpg';
import Anna_List from './assets/Images/Anna_List.jpeg';
import Anna_Action from './assets/Images/Anna_Action.jpeg';
import Emma_List from './assets/Images/Emma_List.jpeg';
import Emma_Action from './assets/Images/Emma_Action.jpeg';
import Niamh_List from './assets/Images/Niamh_List.jpeg';
import Niamh_Action from './assets/Images/Niamh_Action.jpg';
import Darcey_List from './assets/Images/Darcey_List.jpeg';
import Darcey_Action from './assets/Images/Darcey_Action.jpeg';
import Botond_List from './assets/Images/Botond_List.jpeg';
import Botond_Action from './assets/Images/Botond_Action.jpeg';
import HannahRose_List from './assets/Images/HannahRose_List.jpeg';
import HannahRose_Action from './assets/Images/HannahRose_Action.jpeg';
import Kaelan_List from './assets/Images/Kaelan_profile.png';
import Kaelan_Action from './assets/Images/Kaelan_action.jpeg';
import Kevin from './assets/Images/Kevin.jpeg';
import John from './assets/Images/John.jpeg';
import Martin from './assets/Images/Martin.jpeg';
import Colin from './assets/Images/Colin.jpeg';
import Siobhan from './assets/Images/Siobhan.jpeg';
import Rachel from './assets/Images/Rachel.jpeg';
import Michael from './assets/Images/Michael.jpeg';
import StephenBell from './assets/Images/Stephen_Bell.jpeg';
import StephenBellJudge from './assets/Images/StephenBellJudge.jpeg';
import Nikkala from './assets/Images/Nikkala.jpeg';
import Matthew from './assets/Images/Matthew.jpeg';
import Teresa from './assets/Images/Teresa.jpeg';
import Stephen from './assets/Images/Stephen.jpeg';
import Victoria from './assets/Images/Victoria.jpeg';
import Hayley from './assets/Images/Hayley.jpeg';
import Brendan from './assets/Images/Brendan.jpeg';
import BanbridgeKarateClub from './assets/Images/BanbridgeKarateClub.jpeg';
import BKC_Supporting_image from './assets/Images/BKC_Supporting_image.jpeg';
import BKC_Location from './assets/Images/bkc_location.jpeg';
import BKA_Group from './assets/Images/BKA image.jpg';
import BKA_Location from './assets/Images/JKA_Location.jpg';
import BKA_Logo from './assets/Images/BKA_Logo.jpg';
import Newcastle_Logo from './assets/Images/newcastle_logo.jpeg';
import Newcastle_Location from './assets/Images/newcastle_location.jpeg';
import Rathfriland_Location from './assets/Images/rathfriland_location.jpeg';
import Rathfriland_Group from './assets/Images/rathfriland_group.jpeg';
import Ophir_Logo from './assets/Images/ophir_logo.jpeg';
import Ophir_Group from './assets/Images/ophir_group.jpeg';
import Crumlin_Location from './assets/Images/Crumlin_Location.jpeg';
import Killyleagh_Location from './assets/Images/Killyleagh_Location.jpeg';
import Craigavon_Group from './assets/Images/Craigavon_Group.jpg';
import Craigavon_Location from './assets/Images/Craigavon_Location.jpg';
import Lurgan_Group from './assets/Images/Lurgan_Group.jpg';
import Lurgan_Location from './assets/Images/Lurgan_Location.jpg';
import Portadown_Group from './assets/Images/Portadown_Group.jpg';
import Portadown_Location from './assets/Images/Portadown_Location.jpg';
import PLC_Logo from './assets/Images/PLC_Logo.jpeg';

const data = {
    boardMembers: [
        {
            name: "Kevin Broderick",
            role: "President",
            image: Kevin,
        },
        {
            name: "Michael McConville",
            role: "Vice-President and Treasurer",
            image: Michael,
        },
        {
            name: "Siobhan Leckey",
            role: "Secretary",
            image: Siobhan,
        },
        {
            name: "John McConville",
            role: "Assistant Secretary",
            image: John,
        },
        {
            name: "Stephen Bell",
            role: "Publicity Officer and Assistant Treasurer",
            image: StephenBell,
        },
        {
            name: "Nikkala Pokojski",
            role: "Registrar and Assistant Publicity Officer",
            image: Nikkala,
        },
        {
            name: "Colin McDonnell",
            role: "Drug Enforcement Officer",
            image: Colin,
        },
        {
            name: "Teresa McDonnell",
            role: "Child Protection Officer",
            image: Teresa,
        },
        {
            name: "Rachel McDonnell",
            role: "Development Officer",
            image: Rachel,
        },
        {
            name: "Matthew Bushby",
            role: "Grants and Funding Coordinator",
            image: Matthew,
        },
        {
            name: "Martin Gilmore",
            role: "Board Member",
            image: Martin,
        },
    ],
    officials: [
        {
            name: "Kevin Broderick",
            role: "Chief Referee",
            image: Kevin,
        },
        {
            name: "Stephen Avery",
            role: "NIKB Judge A",
            image: Stephen,
        },
        {
            name: "Matthew Bushby",
            role: "NIKB Judge A",
            image: Matthew,
        },
        {
            name: "Michael McConville",
            role: "NIKB Judge A",
            image: Michael,
        },
        {
            name: "Siobhan Leckey",
            role: "NIKB Judge B",
            image: Siobhan,
        },
        {
            name: "Teresa McDonnell",
            role: "NIKB Judge B",
            image: Teresa,
        },
        {
            name: "Brendan Molloy",
            role: "NIKB Judge B",
            image: Brendan,
        },
        {
            name: "Hayley Smyth",
            role: "NIKB Judge B",
            image: Hayley,
        },
        {
            name: "Stephen Bell",
            role: "NIKB Panel Member",
            image: StephenBellJudge,
        },
        {
            name: "Victoria Bushby",
            role: "NIKB Panel Member",
            image: Victoria,
        },
        {
            name: "Martin Gilmore",
            role: "NIKB Panel Member",
            image: Martin,
        },
        {
            name: "John McConville",
            role: "NIKB Panel Member",
            image: John,
        },
        {
            name: "Colin McDonnell",
            role: "NIKB Panel Member",
            image: Colin,
        },
    ],
    coaches: [
        {
            details: {
                name: "Siobhan Leckey",
                rank: "5th Dan",
                role: "National Kata Coach",
                description: "Meet the National Kata Coach of Northern Ireland; Siobhan has been practicing Wado Ryu karate since the age of 4! She has trained under many experience Karate-ka, including Sensei Tatso Suzuki, Sensei Tagaki, Sensei Arakawa, Sensei Hakoishi, Sensei Nishimura, Sensei Sakagami to name a few; her one regret is not having had the opportunity to train under the founder of Wado-Ryu, Master Hironorii Otuska, who passed away in Jan 1982. Her current Sensei is Sensei Derek Desmond JKF Wado-Kai 7th Dan. \n\nSiobhan currently holds JKF Wado-Kai 5th Dan, Instructor licence and 3rd Kyu Examiner licence. Throughout her karate career, Siobhan has excelled in the performance of kata, performing Wado-Ryu katas on the world stage. Her most valued achievements are attaining 5th place at the European Championships (EKF) and 13th place at the World Championship (WKF) in 1994.  More recently, Siobhan competed in the Wado-Kai All Nations Championship in 2018 and took gold in the Female Veterans Kata category, proving that with her love of kata and dedication to studying and practicing kata, she still has the level of skill required to be top of her class. \n\nIn 2023, Siobhan re-joined the NIKB and has become the National Kata Coach, relishing the opportunity to coach and develop a new generation of kata champions.  Siobhan intends to inspire and encourage others to reap the rewards and enjoy the benefits of kata training, whether on the competition tatami or personally in their own dojo.",
            },
            images: {
                list: Siobhan,
                profile: Siobhan_Competing,
                action: Siobhan_Competing,
            },
        },
        {
            details: {
                name: "Nikkala Pokojski",
                rank: "2nd Dan",
                role: "National Kumite Coach",
                description: "Meet the National Kumite Coach of Northern Ireland, a seasoned practitioner with a 2nd Dan black belt in Wado Ryu Karate. \n\nOriginating her journey at Rathfriland Karate Club under Sensei Kevin Broderick at the age of 7, she swiftly ascended the ranks, attaining her black belt by the age of 11. Throughout her career, she has graced prestigious stages, competing in the WKF World and European Championships as a representative of Northern Ireland. Notable accolades include a bronze medal at the 2022 Commonwealth Games, 3x Irish International Open Champion and bronze at the European Wado-Kai Championships in Belgium. \n\nNikkala assumed the role of National Kumite Development Coach in 2021, specialising in training children under 11 years of age. At the beginning of 2024, she was appointed as the National Kumite Coach, heralding a new chapter in her commitment to nurturing karate talent in Northern Ireland. Nikkala is deeply passionate about sharing her extensive knowledge of Kumite, dedicated to helping aspiring athletes reach their full potential and inspiring the next generation of martial artists through her coaching and mentorship.",
            },
            images: {
                list: Nikkala,
                profile: Nikkala_Profile,
                action: Nikkala_Competing,
            },
        },
    ],
    athletes: [
        {
            details: {
                name: "Nikkala Pokojski",
                category: "Senior Female Kumite",
                club: "Rathfriland Karate Club"
            },
            qAndA: {
                one: "I have been practicing Wado-Ryu Karate for 15 years (since I was 7 y/o!). I started in Rathfriland Karate Club.",
                two: "Winning bronze at the 2022 Commonwealth Karate Championships was a major milestone for me and for karate in Northern Ireland. It represents my hard work paying off. I have been Irish Open Champion 3 times and since my first international Gold medal in 2015, I have won a further 17 international medals.\nI am also proud to say I have represented the NIKB in both the WKF World and European Championships.",
                three: "Winning 9-0 in the Commonwealth Karate Bronze medal match.",
                six: "One of my short term goals is to win some more international medals and be a role model for upcoming Northern Ireland fighters. And a long term goal of mine is to coach athletes to win international medals.",
                seven: "It would have to be flying! Having the ability to fly would open up a world of possibilities and freedom. I could experience the world in a completely unique way. Plus, it would add an exciting element to everyday life and make me stand out as a real-life superhero!",
                eight: "A motto I have lived by since a kid is `Dream it, Believe it, Achieve it`. Aspiring karate athletes should envision their goals, believe in themselves and don't give up pursuing their goals through hard work and determination.",
            },
            images: {
                list: Nikkala,
                profile: Nikkala_Profile,
                action: Nikkala_Competing,
            },
        },
        {
            details: {
                name: "Anna Cunningham",
                category: "Senior Female Kata",
                club: "Ophir Karate Club"
            },
            qAndA: {
                one: "19 years",
                two: "Winning the Northern Ireland Karate Board Championship\nBeing part of the winning team for the ONAKAI league 3 years in a row\nRanking 3rd in ONAKAI U21 kata\nRanking 4th in Wado European Championships\nMedalled at the ONAKAI national league finals\nWinning WKAI league multiple years in a row\nRanking 2nd in Irish Wado Championship\nRanking 2nd in Mid Ulster Championships\nCompeting in the commonwealth games",
                three: "My most memorable competition was being selected to represent my club at the most recent commonwealth games.",
                six: "My short term goal is to represent my country successfully at the wado world championship. Long term I would like to be achieve a wkf ranking.",
                seven: "Quick recovery - so I can always be 100% fit for any competition. ",
                eight: "Throw yourself fully into it. Karate is not for everyone, it is a hard sport which takes along time to learn. Karate is not an easy sport and takes a lot of dedication, you may not see the results at the start but with the right attitude and right discipline the results will start to show.",
            },
            images: {
                list: Anna_List,
                profile: Anna_Action,
                action: Anna_Action,
            },
        },
        {
            details: {
                name: "Niamh Cunningham",
                category: "Senior Female Kata",
                club: "Ophir Karate Club"
            },
            qAndA: {
                one: "Over 20 years. I began in 2004.",
                two: "Multiple time WADO Irish champion\nMid Ulster champion\nWADO European Kata gold medalist\nWADO European U21 Kata Gold medalist\nWADO European 2x silver medalist in Kumite\nONAKAI All Ireland champion\nCompeting at the Commonwealth Games",
                three: "Winning the U21 WADO Europeans in Budapest, this was my fourth medal and second gold competing there.",
                six: "My short-term goals are to increase my medal tally and begin competing again in kumite. My long-term goal is to be able to compete in the K1 series.",
                seven: "The power of flight - so I don't have to keep giving all my money to EasyJet and Ryanair.",
                eight: "Keep at it. Karate is a tough sport and challenging at times, but sticking with it and seeing the improvements you make will make you all the more glad you didn't give up. It's important to also remember though that it's normal and healthy to take a break when needed to rest and recover.",
            },
            images: {
                list: Niamh_List,
                profile: Niamh_Action,
                action: Niamh_Action,
            },
        },
        {
            details: {
                name: "Rachel McDonnell",
                category: "Senior Female Kata",
                club: "Banbridge Karate Club"
            },
            qAndA: {
                one: "Since I was 8 years old, I am currently almost 20 years practising karate.",
                two: "Competing in the Commonwealth Karate Championships for kata at 23, European Karate Championships for kumite at 15.\nI retired from fighting at 21 due to an injury picked up when I was 15, but my love for kata has allowed me to continue representing the country and bring home medals from all over the UK.",
                three: "Picking up my first gold medal for kata across the water in 2023.\nMaking friends with people from all over the world.\nTraining with Stephy Connell (GB Kata coach) in January 2023 before getting my first ever bronze medal match at a high level competition.",
                six: "Short term: Continue to compete and bring home as many medals as possible, to show that karate is more than just kumite and kata is more than just doing a little dance in a heavy suit.\nLong term: One day become National kata coach once I have the knowledge to do so, to work alongside a strong and supportive coaching team to push athletes to achieve their goals. Maybe run my own club and allow karate to continue to be accessible to all.",
                seven: "This is a hard one! If it was to be realistic I love to have the superpower of quick healing! Injuries arent fun! My coach would probably say Super Speed so that I would stop being late to everything! Id go for endurance as a super power, so that I can keep training!",
                eight: "Never give up on your goals, take the advice from senior athletes and listen to your coaches! Most importantly turn up to training and give it your all every session. You cannot achieve your goals if you do not give your best effort, that's in both training and competition. Remember your coaches have clubs to run, so your achievements are your responsibility!",
            },
            images: {
                list: Rachel,
                profile: Rachel_Competing,
                action: Rachel_Competing,
            },
        },
        {
            details: {
                name: "Hannah-Rose Grady",
                category: "Under 21 Female Kumite and Kata",
                club: "Banbridge Karate Club"
            },
            qAndA: {
                one: "I have been practising karate for 10 years, starting at age 8.",
                two: "I currently hold the title of National Kata Champion in the junior female category, as well as vice National champion in -68kg junior female kumite. I have also achieved two bronze medals in the Irish International Open, and two bronze medals in the North East Open in Durham.",
                three: "My most memorable competitive experience was at the Irish International Open in 2019, when I achieved my first international medal. This experience was particularly memorable for me as it was the first time I realised I had the ability to compete at international level and wanted to see how far I could go competitively in this sport, this passion still exists within me today.",
                six: "My short term goal in karate is always to perform better than my previous performance, and improve my karate each time I compete. Getting through more rounds, scoring higher or more points in both kata and kumite. Long term I aim to achieve 1st Dan Black Belt before I turn 21, as well as qualifying for European Championships and becoming a member of the Northern Ireland national team.",
                seven: "If I could have any superpower I would choose invisibility, because I think it would be endlessly entertaining to sneak up on people and move objects around in public and watch people's reactions.",
                eight: "The advice I would give to aspiring karate athletes would be to put your enjoyment of the sport first. I have been the most successful in karate when I have I have enjoyed the experience. Don't always allow pressure to improve and perform well take over, strive to improve but love what you do.",
            },
            images: {
                list: HannahRose_List,
                profile: HannahRose_Action,
                action: HannahRose_Action,
            },
        },
        {
            details: {
                name: "Bjanas Kasperiunas",
                category: "Junior Male Kumite and Kata",
                club: "PLC Karate Club"
            },
            qAndA: {
                one: "I started karate when I was 7 in 2016, but left in 2020 due to covid but I made my return in 2022. So about 7 years total",
                two: "Entering the onakai irish open 2023 which was a huge step to the normal competitions I usually go to",
                three: "Being kata mid ulster champion in 2023 using a black belt kata for the first time as a blue belt at the time\nPicking up a gold medal for kumite at the irish wado open",
                six: "Short term: to improve my katas and skills in kumite to win competitions and make my coaches and parents proud\nLong term: to hopefully make it to black belt and be a known worldwide athlete in competitions",
                seven: "If I could have a superpower, i would want to time travel so I could go back in time to fix my mistakes, not only in karate, but in general life",
                eight: "Just because someone beat you in kata or kumite, doesn't mean you're bad at it. Remember, some people wish they were as good as you! you can always improve and ask senior coaches for advice. When I'm stuck with something, my coach Michael is the first person I go to!",
            },
            images: {
                list: Bjan_List,
                profile: Bjan_Action,
                action: Bjan_Action,
            },
        },
        {
            details: {
                name: "Darcey Robinson",
                category: "Cadet Female Kumite and Kata",
                club: "Belfast Karate Academy"
            },
            qAndA: {
                one: "I have been practicing Shotokan Karate for 7 years.",
                two: "At the 2023 NIKB National Championships I secured Gold, becoming Cadet Female Kata National Champion.  In that competition I also placed Bronze in Cadet Female Kumite.\nAt 32nd North South Championships 2024 I secured Gold in both Kata and Kumite Cadet Female categories.",
                three: "The most memorable experience was winning a Gold Medal for the first time in a category filled with a lot of talented athletes.",
                six: "My short term goal is to progress to Dan Grade.\nMy long term goal is to continue to improve my competition performance at local & national level so that I may eventually compete on the International circuit.",
                seven: "If I could have one super power it would be to control time because with that power literally anything is possible.",
                eight: "My advice would be to train hard, don't give up and work on flexibility :)",
            },
            images: {
                list: Darcey_List,
                profile: Darcey_Action,
                action: Darcey_Action,
            },
        },
        {
            details: {
                name: "Botond Kovacs",
                category: "Cadet Male Kumite and Kata",
                club: "PLC Karate Club"
            },
            qAndA: {
                one: "Since I was 7 years old. I have roughly 8 years of experience now.",
                two: "I have won Gold in Kumite and Silver in both individual and team kata within my club.\nBronze in kata at Wado Karate Alliance 2024.\nSilver in team kata at the Mid Ulster 2023.\nBronze in team kata Irish Wado 2024",
                three: "Earning the same placement as my coach in the County Meath Competition. Finally overcoming my friend at Kumite in gold after him consistently winning against me previously. Getting to hear advice and criticism from coaches other than ones from my club.",
                six: "Short term: Try to achieve better and better medals in more competitions, especially trying to advance in kata.\nLong term: Try to gain knowledge and prowess to be able to become a karate coach and help share the gift of martial arts. I also want to advance in grading, trying to get a Dan grade near my 18th Birthday.",
                seven: "Probably shape-shifting to be able to pretend to be a professional coach, secretly sit in on their meetings, and get their karate secrets",
                eight: "If your going to learn a competition kata - make sure you pick one your willing to commit to, and the people who you learn it from like teaching it. The best way to stay consistent and motivated in practise it to make it be more fun, instead of a chore, both for you and your teacher.",
            },
            images: {
                list: Botond_List,
                profile: Botond_Action,
                action: Botond_Action,
            },
        },
        {
            details: {
                name: "Kaelan Greene",
                category: "Cadet Male Kumite and Kata",
                club: "Belfast Karate Academy"
            },
            qAndA: {
                one: "1 year 3 months",
                two: "Silver Kumite - Brunton Cup\nGold Kata and Bronze Kumite - Mid Ulster championships\nBronze Kata - NIKB championships\nBronze in team kata Irish Wado 2024",
                three: "Most memorable experience is when I got kicked in the head really hard it made me dizzy but I carried on fighting.",
                six: "Short term goal- win gold medal kumite next competition. Long term goal - become a world karate champion.",
                seven: "Super speed so I could kick and punch someone before they even blink their eyes.",
                eight: "Use what you find useful, and never fight using your mind, fight using your instincts.",
            },
            images: {
                list: Kaelan_List,
                profile: Kaelan_Action,
                action: Kaelan_Action,
            },
        },
        {
            details: {
                name: "Emma Boyce",
                category: "Cadet Female Kumite",
                club: "Banbridge Karate Club"
            },
            qAndA: {
                one: "I started karate in 2018, at the age of 9.",
                two: "I am currently N.I. champion in kumite. Mid Ulster champion x3 (individual kata, team kata, pair kata).",
                three: "My most memorable karate experience would be when I won Mid ulster champion 2019 (kata). It was my first ever competition and I was so pleased when I won.",
                six: "My short-term goal is to hopefully achieve my purple belt before the end of summer. My long-term goal is to travel, compete, and represent Northern Ireland overseas.",
                seven: "If I could have any superpower, I would choose to be able to read minds. This would help me read a fight and know what they are going to do so I can defend and counter.",
                eight: "My advice for any aspiring karate althetes would be to never give up and always give 100 percent in training because what you put into training is what will come out of it.",
            },
            images: {
                list: Emma_List,
                profile: Emma_Action,
                action: Emma_Action,
            },
        },
        {
            details: {
                name: "Aoibheann Harding-Lester",
                category: "Cadet Female Kumite",
                club: "Rathfriland Karate Club"
            },
            qAndA: {
                one: "I have been doing karate since I was 5 years old, so I have been doing it for 9 years.",
                two: "I have won Gold in kumite at the North South Championships, the Wado Cup Crumiln, KUNI Interclub competition and Meath Championships in the first half of 2024.",
                three: "My most memorable competition is the Four Nations in Durham 2024. It is my most memorable competition because it was my first big competition away from home and although I didn't win, I still got lots of experience from fighting people at such high levels.",
                six: "My short term goals are to use the experience from my competition in England and use it in my next competition. I also would like to work towards my purple belt. My long term goal is to get my black belt.",
                seven: "If I could have a super power it would have to be teleportation because I would never be late to training and I could sleep in later for school. It would also be cool so I could visit my cousins in England more often.",
                eight: "My advice for upcoming karate athletes is to keep trying and turing up to training because you will only get better by practicing.",
            },
            images: {
                list: Aoibheann_List,
                profile: Aoibheann_Action,
                action: Aoibheann_Action,
            },
        },
    ],
    clubs: [
        {
            name: "Banbridge Karate Club",
            town: "Banbridge",
            county: "Down",
            address: "Monday:\nBridge Primary School,\n70 Ballygowan Road,\nBanbridge,\nBT32 3EL\n\nSaturday:\nBanbridge Leisure Centre,\n15 Downshire Place,\nBanbridge,\nBT32 3JY",
            instructor: "Sensei Kevin Broderick 3rd Dan",
            about: "Welcome to Banbridge Karate Club, where champions are forged on the tatami. Home to Irish, British and European titleholders and medalists, our club is led by Sensei Kevin Broderick, a 3rd Dan expert who is president of both the Karate Union of Northern Ireland and the Northern Ireland Karate Board. Affiliated to the KUNI, the club teaches Wado Ryu Karate to children and adults fo all abilities. The club is also home to the National Kumite Coach, Nikkala Pokojski, who is the current Bronze Commonwealth Senior Ladies medal holder. Banbridge Karate Club also have a strong group of coaches to assist you throughout your karate journey. Join us as we strive for greatness, shaping the future of karate one disciplined strike at a time.",
            trainingTimes: "Monday 7pm-8:30pm\n\nSaturday 9am-10:30am",
            contactInfo: {
                email: "broderick.kevin@sky.com",
                phone: "07789231062\nBanbridge Leisure Centre: 02840628800"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/bandrkarate/",
                facebook: "https://www.facebook.com/BandRKC/?locale=en_GB",
            },
            images: {
                location: BKC_Location,
                club: BKC_Supporting_image,
                group: BanbridgeKarateClub,
            }
        },
        {
            name: "Belfast Karate Academy",
            town: "Belfast",
            county: "Antrim",
            address: "Merville House,\nMerville Garden Village,\nNewtownabbey,\nBT37 9TH",
            instructor: "Sensei Stephen Bell 3rd Dan JKA",
            about: "Established in 2017 the Club teaches Shotokan Karate to both Children & Adults of all abilities and and is led by past European competitor (and NIKB Publicity Officer) Stephen Bell with the assistance of the Club's Senior Grades and Committee Members.\n\nOur mission is to develop solid karate practices in our students through regular Traditional & Sports based training formats and the provision of opportunities for all who seek to excel.",
            trainingTimes: "Tuesday 7pm-8:30pm\n\nFriday 7pm-9pm",
            contactInfo: {
                email: "info@belfastkarateacademy.com",
                phone: "07824 505900"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/belfastkarateacademy?igsh=Znl5cjg2ZTh0eHJ3",
                facebook: "https://www.facebook.com/share/8Q4mM8XESewbk4cz/",
                threads: "https://www.threads.net/@belfastkarateacademy?xmt=AQGzPqrjvs6Nq7BLowEkjG6KVRGpSV-dXQySaYpNjqARDU4",
                youtube: "https://youtube.com/@belfastkarateacademy?feature=shared",
                tiktok: "https://www.tiktok.com/@belfastkarateacademy?_t=8pAwqTSlvRB&_r=1"
            },
            images: {
                location: BKA_Location,
                club: BKA_Logo,
                group: BKA_Group
            }
        },
        {
            name: "Ophir Belfast",
            town: "Belfast",
            county: "Antrim",
            address: "Ophir Gardens,\nBelfast,\nBT15 5EP",
            instructor: "Sensei Siobhan Leckey 5th Dan",
            about: "Club founded Sep 1995 by Siobhan Leckey and Martin Campbell. Siobhan is 5th Dan JKF WadoKai and a JKF certified Instructor, who has represented Ireland on the world stage in Female Kata; she is a former Irish, British All Styles and European WadoKai champion and won the JKF WadoKai All Nations Female Veteran Kata category in 2018.\n\nOur students have the opportunity to sit worldwide recognised JKF WadoKai Dan grades. Our principal objective is to safely develop individuals in the practice and development of the art of Wado Ryu karate and to promote Wado Ryu karate in our communities and wider afield.",
            trainingTimes: "Monday 7:30pm-9pm",
            contactInfo: {
                email: "siobhan_leckey@yahoo.co.uk",
                phone: "07743131056"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/ophir.karate?igsh=MWIwZXkxN3llNjBrcQ%3D%3D&utm_source=qr",
                facebook: "https://www.facebook.com/ophirkarateireland",
            },
            images: {
                club: Ophir_Logo,
                group: Ophir_Group
            }
        },
        {
            name: "Craigavon Karate Club",
            town: "Craigavon",
            county: "Armagh",
            address: "South Lake Leisure Centre,\n1 Lake Rd,\nCraigavon,\nBT64 1AS",
            instructor: "Sensei Michael McConville 3rd Dan",
            about: "Discover the essence of martial arts in North Armagh at the Portadown, Lurgan and Craigavon Karate Club, led by Sensei Michael McConville. Affiliated to the KUNI, our club teaches Wado Ryu Karate to people of ages 5+ and all abilites. Our dojo is a sanctuary of tradition and unity, where students of all levels are welcomed into a community devoted to personal growth and excellence. We cultivate discipline and respect in every student. Step into the journey towards excellence with us!",
            trainingTimes: "Tuesday 7pm-9pm",
            contactInfo: {
                email: "plckarate@hotmail.com",
                phone: "07732185642"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/plckarate/",
                facebook: "https://www.facebook.com/portadownkarate",
            },
            images: {
                location: Craigavon_Location,
                club: PLC_Logo,
                group: Craigavon_Group
            }
        },
        {
            name: "Ophir Crumlin",
            town: "Crumlin",
            county: "Antrim",
            address: "Crumlin Leisure Centre,\n2 Main Street,\nCrumlin,\nBT29 4UP",
            instructor: "Sensei Siobhan Leckey 5th Dan",
            about: "Club founded Sep 1995 by Siobhan Leckey and Martin Campbell. Siobhan is 5th Dan JKF WadoKai and a JKF certified Instructor, who has represented Ireland on the world stage in Female Kata; she is a former Irish, British All Styles and European WadoKai champion and won the JKF WadoKai All Nations Female Veteran Kata category in 2018.\n\nOur students have the opportunity to sit worldwide recognised JKF WadoKai Dan grades. Our principal objective is to safely develop individuals in the practice and development of the art of Wado Ryu karate and to promote Wado Ryu karate in our communities and wider afield.",
            trainingTimes: "Thursday 7pm-8pm",
            contactInfo: {
                email: "siobhan_leckey@yahoo.co.uk",
                phone: "07743131056"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/ophir.karate?igsh=MWIwZXkxN3llNjBrcQ%3D%3D&utm_source=qr",
                facebook: "https://www.facebook.com/ophirkarateireland",
            },
            images: {
                location: Crumlin_Location,
                club: Ophir_Logo,
                group: Ophir_Group
            }
        },
        {
            name: "Ophir Killyleagh",
            town: "Killyleagh",
            county: "Down",
            address: "The Bridge Community Centre,\n27 Braeside Gardens,\nKillyleagh,\nDownpatrick,\nBT30 9QE",
            instructor: "Sensei Darren Brown",
            about: "Club founded Sep 1995 by Siobhan Leckey and Martin Campbell. Siobhan is 5th Dan JKF WadoKai and a JKF certified Instructor, who has represented Ireland on the world stage in Female Kata; she is a former Irish, British All Styles and European WadoKai champion and won the JKF WadoKai All Nations Female Veteran Kata category in 2018.\n\nOur students have the opportunity to sit worldwide recognised JKF WadoKai Dan grades. Our principal objective is to safely develop individuals in the practice and development of the art of Wado Ryu karate and to promote Wado Ryu karate in our communities and wider afield.",
            trainingTimes: "Thursday 7:15pm-8:15pm",
            contactInfo: {
                email: "themonk7@hotmail.com",
                phone: "07789390454"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/ophir.karate?igsh=MWIwZXkxN3llNjBrcQ%3D%3D&utm_source=qr",
                facebook: "https://www.facebook.com/ophirkarateireland",
            },
            images: {
                location: Killyleagh_Location,
                club: Ophir_Logo,
                group: Ophir_Group
            }
        },
        {
            name: "Lurgan Karate Club",
            town: "Lurgan",
            county: "Armagh",
            address: "Jethro Centre,\nFlush Place,\nLurgan,\nBT66 7DT",
            instructor: "Sensei Michael McConville 3rd Dan",
            about: "Discover the essence of martial arts in North Armagh at the Portadown, Lurgan and Craigavon Karate Club, led by Sensei Michael McConville. Affiliated to the KUNI, our club teaches Wado Ryu Karate to people of ages 5+ and all abilites. Our dojo is a sanctuary of tradition and unity, where students of all levels are welcomed into a community devoted to personal growth and excellence. We cultivate discipline and respect in every student. Step into the journey towards excellence with us!",
            trainingTimes: "Saturday 10:30am-12pm",
            contactInfo: {
                email: "plckarate@hotmail.com",
                phone: "07732185642"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/plckarate/",
                facebook: "https://www.facebook.com/portadownkarate",
            },
            images: {
                location: Lurgan_Location,
                club: PLC_Logo,
                group: Lurgan_Group
            }
        },
        {
            name: "Newcastle Karate Club",
            town: "Newcastle",
            county: "Down",
            address: "St John's Parish Hall,\nThe Rock,\n161 Central Promenade,\nNewcastle,\nBT33 OEU",
            instructor: "Sensei Mark Magorrian",
            about: "Discover strength, discipline and community at Newcastle Karate Club in County Down. Affiliated to the KUNI, our club teaches Wado Ryu Karate to people of all ages and abilites. Our experienced instructors welcome practitioners of all levels to join us in honing skills, building character and forging friendships. Step onto the mat and unleash your potential with us.",
            trainingTimes: "Saturday 11am-12pm",
            contactInfo: {
                email: "-",
                phone: "07891071322"
            },
            images: {
                location: Newcastle_Location,
                club: Newcastle_Logo,
                group: ""
            }
        },
        {
            name: "Portadown Karate Club",
            town: "Portadown",
            county: "Armagh",
            address: "Masonic Hall,\nThomas Street,\nPortadown,\nBT62 3AG",
            instructor: "Sensei Michael McConville 3rd Dan",
            about: "Discover the essence of martial arts in North Armagh at the Portadown, Lurgan and Craigavon Karate Club, led by Sensei Michael McConville. Affiliated to the KUNI, our club teaches Wado Ryu Karate to people of ages 5+ and all abilites. Our dojo is a sanctuary of tradition and unity, where students of all levels are welcomed into a community devoted to personal growth and excellence. We cultivate discipline and respect in every student. Step into the journey towards excellence with us!",
            trainingTimes: "Thursday 6pm-9pm",
            contactInfo: {
                email: "plckarate@hotmail.com",
                phone: "07732185642"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/plckarate/",
                facebook: "https://www.facebook.com/portadownkarate",
            },
            images: {
                location: Portadown_Location,
                club: PLC_Logo,
                group: Portadown_Group
            }
        },
        {
            name: "Rathfriland Karate Club",
            town: "Rathfriland",
            county: "Down",
            address: "Rathfriland Bowling Club,\nDownpatrick Street,\nRathfriland,\nBT34 5PX",
            instructor: "Sensei Kevin Broderick 3rd Dan",
            about: "Welcome to Rathfriland Karate Club, where champions are forged and excellence is the standard. Our dojo, affiliated with the Karate Union of Northern Ireland, teaches Wado Ryu Karate and has a produced Irish, British and European titleholders and medalists. The club is also home to the National Kumite Coach, Nikkala Pokojski, who is the current Bronze Commonwealth Senior Ladies Kumite medal holder. Leading our journey is Sensei Kevin Broderick, a 3rd Dan black belt and president of both the KUNI and the NIKB. Join us as we train, compete, and strive for greatness, leaving our mark on the karate world.",
            trainingTimes: "Wednesday 6:30pm-8pm",
            contactInfo: {
                email: "broderick.kevin@sky.com",
                phone: "07789231062\nRathfriland Community Centre: 02840638188"
            },
            socialMediaLinks: {
                instagram: "https://www.instagram.com/bandrkarate/",
                facebook: "https://www.facebook.com/BandRKC/?locale=en_GB",
            },
            images: {
                location: Rathfriland_Location,
                club: BKC_Supporting_image,
                group: Rathfriland_Group
            }
        },
    ]
};

function Data() {
    return data;
}

export {
    Data,
};
