import React from 'react';
import Footer from '../../components/Footer/Footer';
import MembershipPromotion from '../../components/MembershipPromotion/MembershipPromotion';
import Navbar from '../../components/Navbar/Navbar';
import WhoWeAreContent from '../../components/WhoWeAre/WhoWeAre';
import './WhoWeAre.css';

function WhoWeAre() {
  return (
    <div className="container">
        <Navbar page="Who we are" />
        <div className="page">
          <br />
          <WhoWeAreContent />
          <br /><br />
          <MembershipPromotion />
        </div>
        <Footer />
    </div>
  );
}

export default WhoWeAre;
