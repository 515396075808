import React from 'react';
import { useTabContext } from '../../Context';
import './Filter.css';

const counties = [
  'Show all',
  'Antrim',
  'Armagh',
  'Down',
];

function Filter() {
  const { selectedCounty, setSelectedCounty } = useTabContext();

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCounty(selectedValue);
  };

  return (
    <div>
      <select className="filter" id="county-dropdown" value={selectedCounty} onChange={handleChange}>
        <option value="" disabled>-- Filter by county --</option>
        {counties.map((county) => (
          <option key={county} value={county}>
            {county}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Filter;
