import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import CoachProfile from '../../components/WhoWeAre/MemberProfile/Coach/CoachProfile';
import SquadProfile from '../../components/WhoWeAre/MemberProfile/Squad/SquadProfile';
import Navbar from '../../components/Navbar/Navbar';
import BackLink from '../../components/BackLink/BackLink';
import './Profile.css';

function Profile() {
  window.scroll(0, 0);
  const { type, name } = useParams();

  const displayName = name.replace('_', ' ');

  return (
    <div className="container">
      <Navbar page="Who we are" />
      <div className="page">
        <BackLink page="/who-we-are" />
        { type === "coach" ?
          <CoachProfile type={type} name={displayName} />
          : <SquadProfile type={type} name={displayName} />
        }
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
