import React from 'react';
import QuickLinkCard from '../../components/Home/QuickLinkCard/QuickLinkCard';
import Footer from '../../components/Footer/Footer';
import MembershipPromotion from '../../components/MembershipPromotion/MembershipPromotion';
import Navbar from '../../components/Navbar/Navbar';
import Kumite_Squad_Training from '../../assets/Images/Kumite_Squad_Training_1.jpeg';
import Kata_Squad_Training from '../../assets/Images/Kata_Squad.jpg'; 
import NIKB_National_Championships from '../../assets/Images/NIKB_National_Championships.png';
import './Events.css';

function Events() {
  return (
    <div className="container">
        <Navbar page="Events" />
        <div className="page">
          <h1>What is happening at the NIKB?</h1>
          <p className="large">Check out our calendar for WKF and NIKB events happening in the near future. If you are interested or would like further information about any events listed, please visit our <a href="/contact-us">contact</a> page to reach out to us.</p>
          <br /><br />
          <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%23ffffff&showTabs=0&showTitle=0&showPrint=0&showCalendars=0&hl=en_GB&src=MGVlOWEyMWM4YzY4OGJhZjRkOWUxMjkwMGIyOTFjY2IwOWMyMmYzZTAwYjFlZjhkOWIwYTQ2NDVkNTlmNTA4OUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%234285F4" id="calendarWebsite" frameBorder="0" title="NIKB Events Calendar" sandbox="allow-same-origin allow-scripts"></iframe>
          <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%23ffffff&showTitle=0&mode=AGENDA&showTabs=0&showPrint=0&showNav=0&hl=en_GB&showCalendars=0&src=MGVlOWEyMWM4YzY4OGJhZjRkOWUxMjkwMGIyOTFjY2IwOWMyMmYzZTAwYjFlZjhkOWIwYTQ2NDVkNTlmNTA4OUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%234285F4" frameBorder="0" id="calendarMobile" title="NIKB Events Calendar" sandbox="allow-same-origin allow-scripts"></iframe>
          <br /><br />
          <h2>NIKB events to watch out for...</h2>
          <div className="quickLinks">
            <QuickLinkCard
              title="Kumite Squad Training"
              image={Kumite_Squad_Training}
              bio="Join us at the NIKB National Kumite Squad session! Sharpen your skills, elevate your technique and bond with fellow martial artists across our clubs. Don't miss out on the chance to enhance your performance and represent the NIKB! Register interest with your club."
              alt="NIKB Kumite Squad group"
            />
            <QuickLinkCard
              title="Kata Squad Training"
              image={Kata_Squad_Training}
              bio="Step into excellence with the Kata Squad! Elevate your artistry, perfect your stances and join a community of dedicated practitioners. Join us and let your spirit shine on the national stage! Register interest with your club."
              alt="NIKB Kata Squad group"
            />
            <QuickLinkCard
              title="NIKB National Championships"
              image={NIKB_National_Championships}
              bio="Get ready to unleash your full potential at the annual NIKB National Karate Championships! Witness the power, precision and passion of top karatekas from across the nation. Compete, spectate and immerse yourself in the spirit of martial arts excellence."
              alt="Birdseye view of NIKB National Championships"
            />
          </div>
          <iframe className="youtube_video" src="https://www.youtube.com/embed/8favqTmXgdM?si=cNlhh2mpe5bRVor0" title="YouTube NIKB Kata Squad" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen sandbox="allow-same-origin allow-scripts"></iframe>
          <br /><br /><br /><br />
          <MembershipPromotion />
        </div>
        <Footer />
    </div>
  );
}

export default Events;
