import React from 'react';
import { Link } from 'react-router-dom';
import './Coach.css';

function Coach(props) {
    const { name, role, image, type } = props;

    return (
        <div className="container">
            <div className="member">
                <div className="memberDetails">
                    <div id="details">
                        <h1>{name}</h1>
                        <h3 id="role">{role}</h3>
                        <Link to={`/profile/${type}/${name.replace(' ', '_')}`} id="profile_link">View profile &gt;</Link>
                    </div>
                    <img className="picture" src={image} alt={`${name} headshot`} />
                </div>
            </div>
        </div>
    );
}

export default Coach;
