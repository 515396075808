import React, { useEffect } from 'react';
import Error from '../../../Error/Error';
import {
  Data,
} from '../../../../data.js';
import AccordionQnA from './atoms/accordion';
import { useTabContext } from '../../../../Context';
import '../MemberProfile.css';

function SquadProfile(props) {
  const { type, name } = props;
  const { setDefaultTab } = useTabContext();

  useEffect(() => {
    setDefaultTab(4);
  }, [setDefaultTab]);

  const data = Data().athletes;

  let athlete;
  let error = true;

  for (let i = 0; i < data.length; i++) {
    if (data[i].details.name === name) {
      athlete = data[i];
      error = false;
    }
  }
  
  return (
    <div>
        { type === "athlete" ?
          <div>
            { error ?
              <Error text="Error: This athlete doesn't exist!" />
            :
              <div className="profile">
                <img className="profile_picture" src={athlete.images.profile} alt={`${name} headshot`} />
                <div>
                  <div className="profile_titles">
                    <h1>{`${name}, ${athlete.details.club}`}</h1>
                    <h3 className="profile_role">{athlete.details.category}</h3>
                  </div>
                  <AccordionQnA
                    question="How long have you been practicing karate?" 
                    answer={athlete.qAndA.one}
                  />
                  <br /><br />
                  <AccordionQnA
                    question="What are some of your competitive achievements (e.g. titles, medals)?" 
                    answer={athlete.qAndA.two}
                  />
                  <br /><br />
                  <AccordionQnA
                    question="What is your most memorable competition experience?" 
                    answer={athlete.qAndA.three}
                  />
                  <br /><br />
                  <AccordionQnA
                    question="What are your short-term and long-term goals in karate?" 
                    answer={athlete.qAndA.six}
                  />
                  <br /><br />
                  <AccordionQnA
                    question="If you could have any superpower, what would it be and why?" 
                    answer={athlete.qAndA.seven}
                  />
                  <br /><br />
                  <AccordionQnA
                    question="What advice would you give to aspiring karate athletes?" 
                    answer={athlete.qAndA.eight}
                  />
                  <br /><br />
                  <img src={athlete.images.action} className="action_picture" alt={`${name} action-shot`} />
                </div>
              </div>
            }
          </div>
        :
          <Error text={`Error: Nice try, but ${type} is not a valid member type! Try "coach" or "athlete" instead.`} />
        }
    </div>
  );
}

export default SquadProfile;
