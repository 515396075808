import * as React from 'react';
import BoardMember from '../BoardMember/BoardMember';
import {
    Data,
} from '../../../data.js';

export default function BoardMembers() {
    const data = Data();
    return (
        <div>
            {data.boardMembers.map(member => (
                <BoardMember
                    name={member.name}
                    role={member.role}
                    image={member.image}
                    key={member.name}
                />
            ))}
        </div>
    );
}
