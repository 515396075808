import React from 'react';
import Typography from '@mui/material/Typography';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import ClubCard from '../../components/ClubCard/ClubCard';
import {
  Data,
} from '../../data.js';
import './OurClubs.css';
import MembershipPromotion from '../../components/MembershipPromotion/MembershipPromotion';
import Filter from '../../components/Filter/Filter';
import Search from '../../components/Search/Search';
import { useTabContext } from '../../Context';

function OurClubs() {
  const data = Data();

  const {
    selectedCounty,
    searchTerm,
  } = useTabContext();

  const filteredClubs = data.clubs.filter((club) =>
    club.town.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container">
        <Navbar page="Our clubs" />
        <div className="page">
          <h1>Looking to learn karate?</h1>
          <p className="large">Explore karate dojos nearby and inquire about their training options. All ages and skill levels, from beginners to experts, are invited to become part of our karate family.</p>
          <br /><br />
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1KxgQTT5VZ-8msChRB9biNrL6z6cK3Y4&ehbc=2E312F&noprof=1" className="clubsMap" title="NIKB Club Locations" sandbox="allow-same-origin allow-scripts"></iframe>
          <br /><br /><br />
          <div className="searchContainer">
            <Search />
            <Filter />
          </div>
          <br /><br className="break" />
          <Typography component="div">
            <div className="clubList">
              {filteredClubs.length === 0 && (
                <h1 id="oops">Oops! No clubs match your search!</h1>
              )}
              {filteredClubs?.map(club => (
                (club.county === selectedCounty || !selectedCounty || selectedCounty === "Show all") && (
                  <ClubCard info={club} key={club.name} />
                )
              ))}
            </div>
          </Typography>
          <br /><br />
          <MembershipPromotion />
        </div>
        <Footer />
    </div>
  );
}

export default OurClubs;
