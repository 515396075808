import React, { useEffect } from 'react';
import Error from '../../../Error/Error.jsx';
import { useTabContext } from '../../../../Context.jsx';
import {
  Data,
} from '../../../../data.js';
import '../MemberProfile.css';

function CoachProfile(props) {
  const { name } = props;
  const { setDefaultTab } = useTabContext();

  useEffect(() => {
    setDefaultTab(2);
  }, [setDefaultTab]);

  const data = Data().coaches;

  let coach;
  let error = true;

  for (let i = 0; i < data.length; i++) {
    if (data[i].details.name === name) {
      coach = data[i];
      error = false;
    }
  }

  return (
    <div>
      { error ?
        <Error text="Error: This coach doesn't exist!" />
      :
        <div className="profile">
          <img className="profile_picture" src={coach.images.profile} alt={`${name} headshot`} />
          <div>
            <div className="profile_titles">
              <h1>{`${name}, ${coach.details.rank}`}</h1>
              <h3 className="profile_role">{coach.details.role}</h3>
            </div>
            <p>{coach.details.description}</p>
            <img src={coach.images.action} className="action_picture" alt={`${name} action-shot`} />
          </div>
        </div>
      }
    </div>
  );
}

export default CoachProfile;
